import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { edges } = allMarkdownRemark
  const { frontmatter, html, timeToRead, wordCount, excerpt } = markdownRemark
  var articleContext = ""
  edges.forEach(t => {
    if (t.node.frontmatter.slug === frontmatter.slug) {
      articleContext = t
    }
  })
  console.log(articleContext)
  return (
    <Layout>
      <SEO title={frontmatter.title} description={excerpt} />
      <div className="px-2 md:px-8 blog-post-container w-full lg:w-1/2 mx-auto max-w-screen-lg">
        <div className="blog-post card">
          <div
            id="article-info-box"
            className="flex justify-between items-center flex-row flex-wrap mt-1 font-thin px-2 md:px-5 pt-3"
          >
            <div>
              Published on{" "}
              <span className="font-semibold">{frontmatter.date}</span> by{" "}
              <span className="font-semibold">{frontmatter.author}</span>
            </div>
            <div className="text-xs leading-4">
              <div>{wordCount.words} Words</div>
              <div>
                {timeToRead === 1
                  ? timeToRead + " minute"
                  : timeToRead + " minutes"}{" "}
                to read
              </div>
            </div>
          </div>
          <h1 className="px-2 md:px-5" id="article-title">
            {frontmatter.title}
          </h1>
          <main
            className="px-2 md:px-5 blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />

          {articleContext.previous !== null || articleContext.next !== null ? (
            <div
              id="article-neighbours"
              className="flex flex-wrap flex-row justify-between mb-2 text-xs leading-4 px-2 md:px-5 pt-2"
            >
              <div className="text-left w-1/2">
                {articleContext.previous ? (
                  <div>
                    <div>Previous Article</div>
                    <div>
                      <Link
                        className="font-semibold"
                        to={articleContext.previous.frontmatter.slug}
                      >
                        {articleContext.previous.frontmatter.title}
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="text-right w-1/2">
                {articleContext.next ? (
                  <div>
                    <div>Next Article</div>
                    <div>
                      <Link
                        className="font-semibold"
                        to={articleContext.next.frontmatter.slug}
                      >
                        {articleContext.next.frontmatter.title}
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      limit: 1000
      filter: { frontmatter: { type: { eq: "article" } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
            date
            title
          }
        }
        next {
          frontmatter {
            slug
            date
            title
          }
        }
        previous {
          frontmatter {
            slug
            date
            title
          }
        }
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      excerpt(format: PLAIN)
      html
      timeToRead
      wordCount {
        words
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        author
      }
    }
  }
`
